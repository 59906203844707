const $window = $(window);
const $body = $('body');
import {FormFilterClass} from './modules/FormFilterClass'
import Swiper from 'swiper/js/swiper'
import {LazyImage, LazyImages} from './modules/lazyImages'
import {FormValidation} from './modules/FormValidation';
import Colcade from 'colcade'
/****************************
 *
 * Product Slider
 *
 ***************************/
import {forEach} from './helpers/forEach'
import Rellax from 'rellax'

// new lazyLoad($('#ajax-results'), 200);

let ajaxContainer = document.getElementById('ajax-results');
let loader = document.getElementById('append_loader');
new FormFilterClass({
    form: document.getElementById('filter'),
    container: ajaxContainer,
    isAjax: false,
    loader: loader,
    lazyload: 400,
    onLazyload: () => {
        $(ajaxContainer).find('.lazy-image:not(".active")').each(function () {
            new LazyImage($(this), 200);
        })
    }
});

/****************************
 *
 * Logo animation
 *
 ***************************/


const navLogo = $('.nav-logo__item');
const nav = $('.nav');
$window.on('scroll', function () {
    if ($window.scrollTop() > 100 && !navLogo.hasClass('nav-logo__item--scrolled')) {
        navLogo.addClass('nav-logo__item--scrolled');
        nav.addClass('nav--scrolled');
    } else if ($window.scrollTop() < 100 && navLogo.hasClass('nav-logo__item--scrolled')) {
        navLogo.removeClass('nav-logo__item--scrolled');
        nav.removeClass('nav--scrolled');
    }
});


/****************************
 *
 * Home highlight arrow scroll to
 *
 ***************************/

let height = window.innerWidth < 959 ? 70 : 60;
const homeHighlightArrow = $('.home-highlight__arrow');
if (homeHighlightArrow.length) {
    homeHighlightArrow.on('click', function () {
        $("html, body").stop().animate({
            scrollTop: $('#home-highlight-target').offset().top - height
        }, 500);
    });
}


/****************************
 *
 * Carousels
 *
 ***************************/

const setSwiperHeight = () => {
    let swiper = $('.swiper-container');
    swiper.each(function () {
        let content_height = 0,
            button_height = 0,
            $this = $(this),
            blocks_content = $this.find('.block__desc'),
            blocks_button = $this.find('.block__button');

        blocks_content.css('height', 'unset');
        blocks_content.each(function () {
            if (content_height < $(this).height()) {
                content_height = $(this).height();
            }
        });
        blocks_content.height(content_height);

        blocks_button.css('height', 'unset');
        blocks_button.each(function () {
            if (button_height < $(this).height()) {
                button_height = $(this).height();
            }
        });
        blocks_button.height(button_height);

    });
};

$window.on('resize', setSwiperHeight);
setSwiperHeight();

new Swiper('.swiper-container', {
    speed: 800,

    pagination: {
        clickable: true,
        el: '.swiper-pagination',
    },
    spaceBetween: 0,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

new Swiper('.swiper-container-home', {
    speed: 800,
    loop: true,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        clickable: true,
        el: '.swiper-pagination',
    },
    spaceBetween: 0,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

/****************************
 *
 * Image lazy load
 *
 ***************************/

require('objectFitPolyfill');
new LazyImages($body, 200, function (e) {
    objectFitPolyfill(e);
});


/****************************
 *
 * Filter
 *
 ***************************/

$('[filter-toggle]').on('click', function () {
    let activeClass = 'filter-toggle--active';
    let time = 400;
    let parent = this.parentNode;
    let content = $(this).next();
    if (parent.classList.contains(activeClass)) {
        parent.classList.remove(activeClass);
        content.slideUp(time);
    } else {
        parent.classList.add(activeClass);
        content.slideDown(time);
    }
});


/****************************
 *
 * Product Slider
 *
 ***************************/

new Swiper('.product-swiper', {
    speed: 800,
    pagination: {
        el: '.swiper-pagination',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

const forms = document.getElementsByClassName('form-validation');
if (forms.length > 0) {
    forEach(forms, function (form) {
        const alert = form.dataset.after;

        new FormValidation($(form), {
            inputGroup: 'input-group',
            button: form.querySelector('[type=submit]'),
            onSubmitSuccess: () => {
                let after = document.getElementById('after');
                after.style.display = "block";
                form.style.display = 'none';
            }
        });
    });
}


let navOverlay = $('.nav-mobile-menu');
let hamburger = $('.hamburger');

hamburger.on('click', function () {
    hamburger.toggleClass('is-active');
    navOverlay.toggleClass('is-active');
    $body.toggleClass('is-locked')
});


/****************************
 *
 * Project colcade
 *
 ***************************/

if (document.querySelector('.grid')) {
    let $grid = new Colcade('.grid', {
        columns: '.grid-col',
        items: '.grid-item'
    });
}


/****************************
 *
 * Image Parralox
 *
 ***************************/
if (document.getElementsByClassName('.rellax')) {
    new Rellax('.rellax');
}


$('.home-highlight__cross').on('click', function () {
    $('#home__content').addClass('is-closed');
});

